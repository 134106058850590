button.btn,
a.btn {
  border-radius: 0;
}

button.btn-primary,
a.btn-primary {
  --bs-btn-color: black;
  --bs-btn-bg: #20ffa4;
  --bs-btn-border-color: #20ffa4;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #20ffa4;
  --bs-btn-hover-border-color: #20ffa4;
  --bs-btn-active-color: black;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-bg: #20ffa4;
  --bs-btn-active-border-color: #20ffa4;
  --bs-btn-disabled-bg: #cacaca;
  --bs-btn-disabled-border-color: #cacaca;
}

button.btn-secondary,
a.btn-secondary {
  --bs-btn-color: white;
  --bs-btn-bg: #2424ff;
  --bs-btn-border-color: #2424ff;
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: #2424ff;
  --bs-btn-hover-border-color: #2424ff;
  --bs-btn-active-color: white;
  --bs-btn-active-bg: #2424ff;
  --bs-btn-active-border-color: #2424ff;
  --bs-btn-disabled-color: rgba(70, 40, 221, 0.5);
  --bs-btn-disabled-bg: #cacaca;
  --bs-btn-disabled-border-color: #cacaca;
}

.disable-animation.btn.btn-primary {
  --bs-btn-hover-bg: #7cffc9;
}
.disable-animation.btn.btn-secondary {
  --bs-btn-hover-bg: #5050ff;
}

.buttongeo {
  position: relative;
}

.buttongeo span {
  position: absolute;
  transition: all 200ms ease-in-out;
}

.buttongeo span.line-1 {
  top: -2px;
  left: 50%;
  width: 150%;
  height: 0.1px;
  background: #9d9fab;
  transform: translateX(-50%);
  max-width: calc(100% + 50px);
}

.buttongeo span.line-2 {
  bottom: -1px;
  right: 50%;
  width: 150%;
  height: 0.1px;
  background: #9d9fab;
  transform: translateX(50%);
  max-width: calc(100% + 50px);
}
.buttongeo span.line-1 {
  top: -1px;
  left: 50%;
  width: 150%;
  height: 0.1px;
  background: #9d9fab;
  transform: translateX(-50%);
  max-width: calc(100% + 50px);
}

.buttongeo span.line-3 {
  bottom: -25%;
  right: -1px;
  width: 0.1px;
  height: 150%;
  background: #9d9fab;
  transform: translateY(0%);
  max-width: calc(100% + 50px);
}

.buttongeo span.line-4 {
  bottom: -25%;
  left: -1px;
  width: 0.1px;
  height: 150%;
  background: #9d9fab;
  transform: translateY(0%);
  max-width: calc(100% + 50px);
}
.buttongeo:hover .line-1 {
  transform: translateX(-66%);
}
.buttongeo:hover .line-2 {
  transform: translateX(66%);
}

.buttongeo:hover .line-3 {
  transform: translateY(15%);
}
.buttongeo:hover .line-4 {
  transform: translateY(-15%);
}
