.border-card-top,
.border-card-bottom {
  left: 50%;
  width: calc(100% - 25px);
  height: 0.5px;
  transform: translateX(-50%);
}

.border-card-top {
  top: 8px;
}
.border-card-bottom {
  bottom: 8px;
}

.border-card-right,
.border-card-left {
  top: 50%;
  width: 0.5px;
  height: calc(100% - 10px);
  transform: translateY(-50%);
}

.border-card-right {
  right: 5px;
}
.border-card-left {
  left: 5px;
}

[class^="horizontal-short-bar-"] {
  width: 0.5px;
  height: 4px;
  top: -2px;
}

.horizontal-short-bar-1 {
  left: 0;
}

.horizontal-short-bar-2 {
  right: 0;
}

.horizontal-short-bar-3 {
  left: 8.6%;
}

.horizontal-short-bar-4 {
  left: 25.6%;
}

.horizontal-short-bar-5 {
  right: 34.4%;
}

.horizontal-short-bar-6 {
  right: 18.9%;
}

[class^="vertical-short-bar-"] {
  width: 4px;
  height: 0.5px;
  left: -2px;
}

.vertical-short-bar-1 {
  top: 0;
}

.vertical-short-bar-2 {
  bottom: 0;
}

.vertical-short-bar-3 {
  top: 8.6%;
}

.vertical-short-bar-4 {
  top: 25.6%;
}

.vertical-short-bar-5 {
  bottom: 34.4%;
}

.vertical-short-bar-6 {
  bottom: 18.9%;
}
