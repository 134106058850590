#CardH {
  .h-img-40 {
    height: 40px;
  }
}
#navbar-nav {
  .navbar-nav {
    --bs-nav-link-color: #f7f7f7;
    --bs-nav-link-hover-color: #f7f7f7;
    font-family: "Industry";
    letter-spacing: 1px;
    font-size: 15px;
  }

  @media (max-width: 991px) {
    .active::before,
    .active::after {
      content: "";
      position: absolute;
      bottom: -1px;
      width: 1px;
      height: 9px;
      background-color: #20ffa4;
    }
    .active::before {
      left: -1px;
    }
    .active::after {
      right: -1px;
    }
  }
}
.dropdown-menu {
  border-radius: 0px !important;
}

.border-bottom-green {
  border-bottom: 1px solid #20ffa4;
  position: relative;
}

.verticale-header-line-left {
  position: absolute;
  left: -1px;
  bottom: -1px;
}
.verticale-header-line-right {
  position: absolute;
  right: -1px;
  bottom: -1px;
}

#navbar-toggle {
  background-color: #20ffa4;
  color: black;

  .navbar-toggler-icon {
    width: 1em;
    height: 1em;
  }
  &::before {
    content: "Menu ";
  }
}

.all-tab {
  border-top: 1px solid rgb(235, 235, 235);
  border-left: 1px solid rgb(235, 235, 235);
  border-right: 1px solid rgb(235, 235, 235);
}

.tab-menu {
  border-bottom: 1px solid rgb(235, 235, 235);
  border-left: 4px solid transparent;
  padding: 10px;
}
.tab-menu.active {
  border-left: 4px solid #20ffa4;
}
