.short-bar-left {
  left: 0;
  bottom: 0;
  width: 0.5px;
  height: 10px;
  background-color: #0a0e29;
}
.short-bar-right {
  right: 0;
  bottom: 0;
  width: 0.5px;
  height: 10px;
  background-color: #0a0e29;
}

.border-bottom-dark {
  border-bottom: 1px solid #0a0e29;
}
