.modal-header {
  border: none;

  .btn-close {
    background-color: rgb(0 0 0 / 4%);
    filter: invert(9%) sepia(25%) saturate(1525%) hue-rotate(192deg)
      brightness(90%) contrast(105%);
    opacity: 1;
  }
}
