@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

@font-face {
  font-family: "Neuzeit";
  src: local("NeuzeitGrotesk"),
    url("../public/assets/font/NeuzeitGrotesk.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Industry";
  src: local("Industry"),
    url("../public/assets/font/Industry.ttf") format("truetype");
  font-display: swap;
}

body {
  font-family: "Neuzeit", sans-serif;
  font-weight: 400;

  h1,
  h2,
  h3 {
    font-family: "Industry", sans-serif;
  }
  h4 {
    font-family: "Industry", sans-serif;
  }

  h1,
  .h1 {
    font-size: 42px;
    font-weight: 700;
  }

  h2,
  .h2 {
    font-size: 32px;
    font-weight: 600;
  }

  h3,
  .h3 {
    font-size: 22px;
    font-weight: 500;
  }
  h4,
  .h4 {
    font-size: 15px;
    font-weight: 600;
  }

  .xxl-regular {
    font-size: 35px;
    line-height: 1em;
  }

  .xl-regular {
    font-size: 18px;
    font-weight: 500;
  }

  .l-black {
    font-size: 16px;
    font-weight: 900 !important;
    line-height: 24.4px;
  }

  .l-bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 24.4px;
  }

  .s-semibold {
    font-size: 16px;
    font-weight: 600;
  }

  .m-black {
    font-size: 14px;
    font-weight: 900;
  }

  .m-bold {
    font-size: 14px;
    font-weight: 600;
  }

  .m-regular {
    font-size: 14px;
  }

  .s-light {
    font-family: "Space Grotesk", sans-serif;
    font-size: 12px;
  }

  .xs-semibold {
    font-size: 12px;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    h1,
    .h1 {
      font-size: calc(42px * 0.7);
    }

    h2,
    .h2 {
      font-size: calc(32px * 0.7);
    }

    h3,
    .h3 {
      font-size: calc(22px * 0.7);
    }
    .xl-regular {
      font-size: calc(18px * 0.7);
    }

    .l-black {
      font-size: calc(16px * 0.9);
      line-height: calc(24.4px * 0.9);
    }

    .l-bold {
      font-size: calc(16px * 0.9);
      line-height: calc(24.4px * 0.9);
    }

    .m-regular {
      font-size: calc(14px * 0.9);
    }
  }
}

.white-font {
  color: white;
}
.black-font {
  color: black;
}
.light-font {
  color: #f7f7f7;
}
.dark-font {
  color: #0a0e29;
}
.gray-font {
  color: #9d9fab;
}
.blue-font {
  color: #2424ff;
}
.light-blue-font {
  color: #5555ff;
}
.green-font {
  color: #20ffa4;
}

.white-background {
  background-color: white;
}
.black-background {
  background-color: black;
}
.light-background {
  background-color: #f7f7f7;
}
.dark-background {
  background-color: #0a0e29;
}
.gray-background {
  background-color: #9d9fab;
}
.blue-background {
  background-color: #2424ff;
}
.light-blue-background {
  background-color: #5555ff;
}
.green-background {
  background-color: #20ffa4;
}
.dark-blue-background {
  background-color: #142738;
}
.fw-black {
  font-weight: 900;
}

.clickable {
  cursor: pointer;
}

.scrollable {
  overflow: auto;
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.content {
  min-height: calc(100vh - (68px + 182.4px));

  @media (min-width: 992px) {
    min-height: calc(100vh - (86px + 182.4px));
  }
}
