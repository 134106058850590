[class^="bar-"] {
  position: absolute;
  width: 100%;
  height: 0.5px;
  transform: translateY(-50%);
}

.bar-1 {
  top: 5px;
  left: 0;
  transform: translateY(-50%);
}

.bar-2 {
  left: 0;
  transform: translateY(-50%);
  bottom: 5px;
}

.bar-3 {
  right: 40%;
  transform: rotate(90deg) translateY(-50%);
  top: 50%;
}

.bar-4 {
  left: 40%;
  transform: rotate(90deg) translateY(-50%);
  top: 50%;
}
