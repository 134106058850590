.program {
  margin-top: -20px;
  @media (min-width: 768px) {
    .pt-4em {
      padding-top: 4em;
    }
  }

  .custom-number {
    border: 1px solid #2424ff;
    background-color: white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    flex-shrink: 0;
    flex-grow: 0;
    z-index: 2;
  }

  .right-position {
    right: 0;
    bottom: -16px;
  }
  .left-position {
    left: 0;
    bottom: -16px;
  }

  @media (min-width: 768px) {
    .ps-30px {
      padding-left: 30px;
    }

    .pe-30px {
      padding-right: 30px;
    }

    .right-position {
      right: -15px;
      bottom: -16px;
    }
    .left-position {
      left: -15px;
      bottom: -16px;
    }
    .mt-md-n5 {
      margin-top: -2em;
    }
  }

  .program-number-bar {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 1px;
    height: calc(100% - 100px);
    transform: translateX(-50%);
    background-color: #2424ff;
    @media (max-width: 767px) {
      & {
        top: 40px;
        left: calc(100% - 0.6rem);
        height: calc(100% - 60px);
      }
    }
  }
}
