.underline {
  width: 20%;
  .long-bar {
    width: 100%;
    height: 0.5px;
  }
  .short-bar-first {
    right: 50%;
    width: 0.5px;
    height: 10px;
  }
  .short-bar-second {
    left: 0px;
    width: 0.5px;
    height: 10px;
  }
}
