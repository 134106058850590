@media screen and (min-width: 576px) {
  .home-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 575px) {
  .home-title {
    background-image: url("../../../public/assets/images/home/ingenieur.png");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
  }
}

.underline {
  width: 100%;
  position: absolute;
  bottom: 5px;

  .long-bar {
    width: 100%;
    height: 0.5px;
  }

  .short-bar-second {
    position: absolute;
    right: 50%;
    width: 0.5px;
    height: 10px;
    bottom: -5px;
  }
}
