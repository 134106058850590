.content-header {
  .head-horizontal-bar {
    left: 49%;
    top: -1px;
    width: 98%;
    height: 1px;
    transform: translateX(-50%);
  }

  .head-horizontal-bar-reversed {
    right: 49%;
    top: -1px;
    width: 98%;
    height: 1px;
    transform: translateX(50%);
  }

  .head-horizontal-short-bar-1 {
    width: 1px;
    height: 11px;
    top: -5px;
    left: 5%;
  }
  .head-horizontal-short-bar-2 {
    width: 1px;
    height: 11px;
    top: -5px;
    right: 2%;
  }
  .head-horizontal-short-bar-3 {
    width: 1px;
    height: 11px;
    top: -5px;
    left: 45%;
  }
  .head-horizontal-short-bar-4 {
    width: 1px;
    height: 11px;
    top: -5px;
    left: 75%;
  }

  .head-horizontal-short-bar-1-reversed {
    width: 1px;
    height: 11px;
    top: -5px;
    right: 5%;
  }
  .head-horizontal-short-bar-2-reversed {
    width: 1px;
    height: 11px;
    top: -5px;
    left: 2%;
  }
  .head-horizontal-short-bar-3-reversed {
    width: 1px;
    height: 11px;
    top: -5px;
    right: 45%;
  }
  .head-horizontal-short-bar-4-reversed {
    width: 1px;
    height: 11px;
    top: -5px;
    left: 75%;
  }

  .head-vertical-bar {
    right: 0;
    width: 1px;
    top: -2em;
    height: calc(100% + 2em);
  }

  .head-vertical-bar-reversed {
    left: 0;
    width: 1px;
    top: -2em;
    height: calc(100% + 2em);
  }

  .head-vertical-short-bar-1 {
    width: 13px;
    height: 1px;
    right: -6px;
    top: -2em;
  }
  .head-vertical-short-bar-2 {
    width: 13px;
    height: 1px;
    right: -6px;
    top: 50%;
  }

  .head-vertical-short-bar-1-reversed {
    width: 13px;
    height: 1px;
    left: -6px;
    top: -2em;
  }
  .head-vertical-short-bar-2-reversed {
    width: 13px;
    height: 1px;
    left: -6px;
    top: 50%;
  }

  @media (max-width: 767px) {
    .head-vertical-bar {
      right: 25px;
      top: -1em;
      height: calc(100% + 1em);
    }

    .head-vertical-short-bar-1 {
      right: 19px;
      top: -1em;
    }
    .head-vertical-short-bar-2 {
      right: 19px;
      top: 50%;
    }

    .head-horizontal-bar-reversed,
    .head-horizontal-short-bar-1-reversed,
    .head-horizontal-short-bar-2-reversed,
    .head-horizontal-short-bar-3-reversed,
    .head-horizontal-short-bar-4-reversed {
      display: none;
    }
  }
}
